import { useWorkspaceExistingUsers } from "@supernovaio/cloud/hooks/data/useWorkspaceExistingUsers"
import { UserRole } from "@supernovaio/sdk"

export const useWorkspaceOwner = (workspaceId: string | undefined) => {
  const { data: users, isPending } = useWorkspaceExistingUsers({
    workspaceId,
  })
  const owner = users?.find((user) => user.wsRawRole === UserRole.owner)

  return { data: owner, isPending }
}
